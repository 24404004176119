import { cloneDeep } from 'lodash';
import { Action, Reducer } from 'redux';

import { InitialStateUser } from './initial-state';

import { User } from 'src/model/myTypes';
import { UserActions } from 'src/redux/actions/action-users';
import { getBetsToBetsAdapter } from 'src/redux/mapper/betsMapper';
import { getPublicTableAdapter } from 'src/redux/mapper/publicResultsMapper';

const unloadedState: User = cloneDeep(InitialStateUser);

export const reducer: Reducer<User> = (
  state: User | undefined,
  incomingAction: Action,
): User => {
  if (state === undefined) {
    return { ...unloadedState };
  }
  const action = incomingAction as UserActions;
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        ...action.payload,
      };
    case 'CONFIRM_PASSWORD_RECOVER_LINK':
      return {
        ...state,
        isValid: action.payload,
      };
    case 'GET_USER':
      const sliceUrl = process.env.REACT_APP_API_URL?.split('api')[0];
      const safeEnv =
        process.env.NODE_ENV === 'development' ? `${sliceUrl}` : '/';
      return {
        ...state,
        user_name: action.payload.user_name,
        email: action.payload.email,
        avatar: {
          cropped: !action.payload.avatar.cropped ? null : safeEnv + action.payload.avatar.cropped,
          'cropped@2x': !action.payload.avatar['cropped@2x'] ? null : safeEnv + action.payload.avatar['cropped@2x'],
          original: !action.payload.avatar.original ? null : safeEnv + action.payload.avatar.original,
          fileName: action.payload.avatar.original,
        },
        bio: action.payload.bio !== null ? action.payload.bio : '',
        settings: action.payload.settings,
        initial_balance: action.payload.initial_balance,
        notifications: {
          ...state.notifications,
          notifications_enable: action.payload.notifications.notifications_enable,
          n_betsToAprove: action.payload.notifications.n_betsToAprove,
        },
      };
    case 'GET_USER_SETTINGS':
      return {
        ...state,
        settings: {
          default_strategy_id: action.payload.default_strategy_id,
          default_stake: action.payload.default_stake,
          default_sport_id: action.payload.default_sport_id,
          default_currency: action.payload.default_currency,
          default_date_range: action.payload.default_date_range,
        },
      };
    case 'UPLOAD_PHOTO':
      return {
        ...state,
        avatar: {
          ...state.avatar,
          original: action.payload.original,
        },
      };
    case 'RESET_REQUEST_VALIDATION':
      return {
        ...state,
        isValid: true,
      };
    case 'CHANGE_EXPORT_FILTERS':
      return {
        ...state,
        exportFilters: {
          ...state.exportFilters,
          interval: {
            from: action.payload.interval.from,
            to: action.payload.interval.to,
          },
          sport_id: action.payload.sport_id,
          strategy_id: action.payload.strategy_id,
          competition_id: action.payload.competition_id,
          custom_competition_id: action.payload.custom_competition_id,
          market_id: action.payload.market_id,
          custom_market_id: action.payload.custom_market_id,
          preview: action.payload.preview,
        },
      };
    case 'GET_PUBLIC_RESULTS':
      const betsAdapated = getBetsToBetsAdapter(action.payload.bets);
      const strategyTableAdapter = getPublicTableAdapter(
        action.payload.results_by_strategy,
      );
      const sportTableAdapter = getPublicTableAdapter(
        action.payload.results_by_sport,
      );
      const competitionTableAdapter = getPublicTableAdapter(
        action.payload.results_by_competition,
      );
      const marketTableAdapter = getPublicTableAdapter(
        action.payload.results_by_market,
      );
      return {
        ...state,
        publicResults: {
          hash: action.payload.hash,
          user_name: action.payload.user_name,
          bio: action.payload.bio === null ? '' : action.payload.bio,
          currency: action.payload.currency,
          date_from: action.payload.date_from,
          date_to: action.payload.date_to,
          period_result: action.payload.period_result,
          bets_placed: action.payload.bets_placed,
          win_bets: action.payload.win_bets,
          lost_bets: action.payload.lost_bets,
          void_bets: action.payload.void_bets,
          stake_total: action.payload.stake_total,
          average_profit_per_bet: action.payload.average_profit_per_bet,
          roi: action.payload.roi,
          final_balance: action.payload.final_balance,
          bankroll_evolution: action.payload.bankroll_evolution,
          profit_vs_loss: action.payload.profit_vs_loss,
          results_by_strategy: strategyTableAdapter,
          results_by_sport: sportTableAdapter,
          results_by_competition: competitionTableAdapter,
          results_by_market: marketTableAdapter,
          bets: betsAdapated,
        },
      };
    case 'EXPORT_RESULTS':
      return {
        ...state,
        publicResults: {
          ...state.publicResults,
          hash: action.payload.hash,
        },
      };
    case 'LIST_PUBLIC_RESULTS_BET':
      return {
        ...state,
        publicResultsBets: {
          pagination: {
            orderBy: action.payload.pagination.orderBy,
            orderDir: action.payload.pagination.orderDir,
            offset: action.payload.pagination.offset,
            limit: action.payload.pagination.limit,
          },
          bets: action.payload.bets,
        },
      };
    case 'LOGOUT':
      return {
        ...unloadedState,
      };
  }
  return state;
};
